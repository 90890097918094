import React from "react"
import { useSelector } from "react-redux"
import { Link } from "gatsby"

const CaseStudyList = () => {
  const { language } = useSelector(state => state.global)
  const caseStudyBlock = useSelector(state => state.casestudy.caseStudyBlock)
  const nodes = []
  return (
    <section className="career_block client_block portfolio_block">
      <div className="container">
        <div className="row text_side p-0">
          <div className="txt_block">
            <div className="lftbrdr">
              <h6>{caseStudyBlock.title[language]}</h6>
              <h2>{caseStudyBlock.text[language]}</h2>
              <p>{caseStudyBlock.paragraph[language]}</p>
            </div>
          </div>
        </div>

        <div className="whiteset case_study">
          <div className="casestudy_listing">
            {caseStudyBlock.list.map((pageInfo, index) => (
              <div className="casestudy_box">
                <img
                  src={pageInfo?.img?.sourceUrl}
                  alt={`case-study-${index + 1}`}
                />
                <div className="case_txt">
                  <h6>{pageInfo.companyName}</h6>
                  <p>{pageInfo.title[language]}</p>
                  <Link to={`/casestudy/${pageInfo.link}`}>
                    {pageInfo.anchorText[language]}
                    <i className="arrow arrowtwo" />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default CaseStudyList
