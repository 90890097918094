import React from "react"
import { useSelector } from "react-redux"

const Banner = () => {
  const { language } = useSelector(state => state.global)
  const gqlData = useSelector(state => state.casestudy.banner)

  return (
    <div className="banner about_banner case_studybanner">
      <div className="container">
        <span className="meyil">{gqlData.preTitle[language]}</span>
        <div className="banner_txt">
          <div className="light img-fluid">
            <img alt="hero" src={gqlData.img.sourceUrl} />
          </div>
          <div>
            <h1 className="split-text">{gqlData.title[language]}</h1>
            <p className="split-text">
              <span className="banner_txtclr">
                {gqlData.highlightedText[language]}
              </span>
              {gqlData.text[language]}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
