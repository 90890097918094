import React from "react"
import Banner from "./Banner"
import CasestudyList from "./CaseStudyList"
import Explore from "../Common/Explore"

const CaseStudy = () => (
  <>
    <Banner />
    <CasestudyList />
    <Explore />
  </>
)

export default CaseStudy
