import React from "react"
import { useDispatch } from "react-redux"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  CASESTUDY_PAGE_QUERY_REQUEST,
  CASESTUDY_PAGE_QUERY_SUCCESS,
} from "../store/actionTypes"
import { graphql, StaticQuery } from "gatsby"
import CaseStudyPageContent from "../components/CaseStudy"

const Portfolio = () => {
  const dispatch = useDispatch()
  const query = graphql`
    {
      wdwordpress {
        page(id: "cGFnZToyOTQ") {
          casestudy_contents {
            banner {
              highlightedText {
                en
                sv
              }
              img {
                title
                sourceUrl
              }
              preTitle {
                en
                sv
              }
              text {
                en
                sv
              }
              title {
                en
                sv
              }
            }
            caseStudyBlock {
              list {
                anchorText {
                  en
                  sv
                }
                companyName
                img {
                  title
                  sourceUrl
                }
                link
                title {
                  en
                  sv
                }
              }
              paragraph {
                en
                sv
              }
              text {
                en
                sv
              }
              title {
                en
                sv
              }
            }
            connectedBlock {
              blocks {
                buttontext {
                  en
                  sv
                }
                link
                text {
                  en
                  sv
                }
                title {
                  en
                  sv
                }
              }
              title {
                en
                sv
              }
            }
          }
        }
      }
    }
  `
  return (
    <StaticQuery
      query={query}
      render={({ wdwordpress }) => {
        dispatch({ type: CASESTUDY_PAGE_QUERY_REQUEST })
        dispatch({ type: CASESTUDY_PAGE_QUERY_SUCCESS, payload: wdwordpress })
        return (
          <>
            <Layout>
              <SEO title="Case-Study" pageId="cGFnZToyOTQ=" />
              <CaseStudyPageContent />
            </Layout>
          </>
        )
      }}
    />
  )
}

export default Portfolio
